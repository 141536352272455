<template>
    <div class="app-container calendar-list-container">
        <div class="tl p10">
            <el-input   style="width: 500px;margin-right: 50px" class="filter-item" placeholder="请输入企业名称/社会信用代码" v-model="listQuery.keyword">
            </el-input>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="getList()">搜索</el-button>
        </div>
        <el-table
                :key="tableKey"
                ref="table"
                v-loading="listLoading"
                :data="list"
                :height="tableHeight"
                element-loading-text="加载中..."
                border
                fit
                highlight-current-row
                style="width: 100%"
        >
            <el-table-column type="index" align="center" label="序号" width="100" />
            <el-table-column min-width="100" align="center" label="企业名称">
                <template slot-scope="scope">
                    <span>{{ scope.row.companyName }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="社会信用代码">
                <template slot-scope="scope">
                    <span style="color:#E6A23C">{{ scope.row.creditCode }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="管理员名字">
                <template slot-scope="scope">
                    <span>{{ scope.row.userName }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="手机">
                <template slot-scope="scope">
                    <span>{{ scope.row.userPhone }}</span>
                </template>
            </el-table-column>

            <el-table-column min-width="100" align="center" label="异常次数">
                <template slot-scope="scope">
                    <span style="color: red;font-size: 16px;font-weight: bold">{{ scope.row.abnormality }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="总数">
                <template slot-scope="scope">
                    <span>{{ scope.row.total }}</span>
                </template>
            </el-table-column>

            <el-table-column
                    fixed="right"
                    align="center"
                    :label="'异常占比'"
                    class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-progress :text-inside="true" :stroke-width="22" :percentage="scope.row.percent" :status="scope.row.percent == 100 ? 'exception' : 'warning'"></el-progress>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    align="center"
                    :label="'操作'"
                    class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-button type="primary" @click="viewDistribution(scope.row.companyId,scope.row.companyName)">查看分布</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-container">
            <el-pagination
                    background
                    :current-page="listQuery.page"
                    :page-sizes="[10,20,30, 50]"
                    :page-size="listQuery.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="t-dialog" style="width: 80%;height: 80vh;margin-left: -40%" v-if="mapShow">
            <ve-bmap
                    v-if="mapShow"
                    ref="mapRef"
                    height="100%"
                    :settings="chartSettings"
                    :series="chartSeries"
                    :tooltip="chartTooltip"
                    :events="chartEvents"
            >
            </ve-bmap>
            <div class="t-close" style="font-size: 50px;color: #909399" @click="mapShow = false    ">
                ×
            </div>
            <div class="t-title" style="font-size: 24px">
                分布
            </div>
        </div>
    </div>
</template>

<script>
    import api from "../../../util/extra-api";
    import { mapConfig } from "../DepGragh/custom_map_config";
    export default {
        name: "ShadowCompany",
        data() {
            this.chartTooltip = {
                show: true,
                formatter: function (params, ticket, callback) {
                    if (params.seriesType === "effectScatter") {
                        if(params.value[3])
                            return `离注册地距离:${params.value[3]} km<br>`;
                        else{
                            return `注册地:${params.value[2]}`;
                        }
                    }
                },
            };
            const _this = this;
            this.chartEvents = {
                click: (v) => {
                    //console.log("点击",v)
                },
                finished: (_) => {
                    // _this.getGeo();
                },
            };
            return {
                companyName: '',
                mapShow: false,
                tableKey: 0, // 表格的键
                list: null, // 表格的数据
                total: null, // 表格的数据数量
                listLoading: true, // 表格加载状态
                tableHeight: 450,
                listQuery: {
                    keyword: '',
                    page: 1, // 表格的页面值
                    limit: 20 // 表格的单页数量
                },
                replyData: {
                    fb_id: null,
                    reply_detail: ""
                },
                imgList: [],
                imgDialogWidth: null,
                replyDialogVisible: false,
                imgVisible: false,
                userDialogVisible: false,
                dialogVisible: false,
                userData: "",
                stUserData: null,
                selectedUser: [],
                multipleSelection: [],
                userNum: 0,
                showSend: false,
                rules: {
                    title: [{ required: true, message: "必填项", trigger: "blur" }],
                    content: [{ required: true, message: "必填项", trigger: "blur" }]
                },
                baseUrl: process.env.VUE_APP_BASE_API,
                chartSeries: [],
                pointList: [],
                mapData: [],
                chartSettings: {
                    key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
                    bmap: {
                        center: [120, 30],
                        zoom: 5,
                        roam: true,
                        enableMapClick: false,
                        mapStyleV2: { styleJson: mapConfig },
                    },
                },
            };
        },
        created() {
            //this.getList();
        },
        mounted() {
            this.getList();
        },
        methods: {
            // toggleSelection() {
            //   this.selectedUser.forEach(u => {
            //     this.$refs.multipleTable.toggleRowSelection(u);
            //   });
            // },
            viewDistribution(companyId,companyName){
                this.companyName = companyName
                this.initMap( companyId);

                this.mapShow = true
            },
            getList() {
                this.listLoading = true;
                api.get('/v1/pc/virtual/list',this.listQuery).then(response => {
                    this.tableHeight =
                        window.innerHeight - this.$refs.table.$el.offsetTop - 85;
                    const results = response.data;
                    this.list = results;
                    this.total = response.total;
                    this.listLoading = false;
                });
            },
            switchChange(val) {
                api.put('/v1/pc/evaluation/audit/' + val.id,'').then(response => {
                    this.getList();
                });
            },

            showImg(row) {
                if (row.imgList.length == 1) {
                    this.imgDialogWidth = "25%";
                } else if (row.imgList.length == 2) {
                    this.imgDialogWidth = "30%";
                } else {
                    this.imgDialogWidth = "40%";
                }
                this.imgList = row.imgList;
                this.imgVisible = true;
            },
            // 表格单页数据数量的切换
            handleSizeChange(val) {
                this.listQuery.count = val;
                this.getList();
            },
            // 表格页数的切换
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.selectedUser = this.multipleSelection;
                this.userNum = this.selectedUser.length;
            },
            initMap(companyId) {
                // const loading = this.$loading({
                //     target:document.querySelector('.container'),
                //     lock: true,
                //     text: "数据加载中...",
                //     spinner: "el-icon-loading",
                //     background: "rgba(0, 0, 0, 0.7)",
                // });
                //console.log("chartSettings:",this.chartSettings)
                this.mapShow = false;
                this.chartSeries = [];
                const sendColors = [
                    "#7ffd23",
                    "#ed1941",
                    "#f58220",
                    "#F56C6C",
                    "#7ffd23",
                ];
                // "#33a3dc",#19d4ae
                const receivedColors = [
                    "#23e6fd",
                    "#ed1941",
                    "#f58220",
                    "#F56C6C",
                    "#23e6fd",
                ];
                api.get(`/v1/pc/virtual/heatMap`, {companyId: companyId}).then((response) => {
                    //console.log("response: ", response);
                    let res = response.data;
                    let mapData = [];
                    let pointList = [];
                    if (res.companyDetailVos.length > 0) {
                        mapData = this.convertData(res.companyDetailVos, 1);
                        // mapData = res.companyDetailVos;
                        this.mapData = res.companyDetailVos;
                        pointList.push({
                            longitude: res.companyLocation.split(',')[0],
                            latitude: res.companyLocation.split(',')[1]
                        })
                        res.companyDetailVos.forEach((item) => {
                            let location = item.location;
                            if (location) {
                                let locationArr = location.split(",");
                                pointList.push({
                                    longitude: locationArr[0],
                                    latitude: locationArr[1],
                                });
                            }
                        });
                    }

                    let arr = [];
                    let o = [res.companyLocation.split(',')[0],res.companyLocation.split(',')[1],this.companyName]
                    arr.push({
                        type: "effectScatter",
                        coordinateSystem: "bmap",
                        zlevel: 2,
                        rippleEffect: {
                            //涟漪特效
                            period: 4, //动画时间，值越小速度越快
                            brushType: "stroke", //波纹绘制方式 stroke, fill
                            scale: 4, //波纹圆环最大限制，值越大波纹越大
                        },
                        symbol: "circle",
                        itemStyle: {
                            normal: {
                                // color: receivedColors[item[0][3]],
                                color: receivedColors[0],
                            },
                        },
                        data: [o],
                    });
                    if (res.companyDetailVos.length > 0 || res.companyDetailVos.length > 0) {
                        mapData.forEach((item, index) => {
                            arr.push({
                                type: "effectScatter",
                                coordinateSystem: "bmap",
                                zlevel: 2,
                                rippleEffect: {
                                    //涟漪特效
                                    period: 4, //动画时间，值越小速度越快
                                    brushType: "stroke", //波纹绘制方式 stroke, fill
                                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                                },
                                symbol: "circle",
                                itemStyle: {
                                    normal: {
                                        // color: sendColors[item[0][3]],
                                        color: sendColors[1],
                                    },
                                },
                                data: item,
                            });
                        });

                        this.chartSeries = arr;
                    }
                    this.mapShow = true;
                    this.pointList = pointList;
                    this.$nextTick((_) => {
                        this.getGeo();
                    });
                    //loading.close();
                });
            },
            convertData(data, type) {
                let result = [];
                for (let i = 0; i < data.length; i++) {
                    let location =
                        type == 1 ? data[i].location : data[i].location;
                    if (location) {
                        let locationArr = location.split(",");
                        let longitude = locationArr[0];
                        let latitude = locationArr[1];
                        let companyName = data[i].companyName;
                        let formattedData = [
                            [
                                longitude,
                                latitude,
                                this.companyName,
                                data[i].distance,
                            ],
                        ];
                        result.push(formattedData);
                    }
                }
                return result;
            },
            getGeo() {
                setTimeout((_) => {
                    let pointArr = [];
                    this.pointList.forEach((item) => {
                        pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
                    });
                    let echarts = this.$refs.mapRef.echarts;
                    let map = echarts.getModel().getComponent("bmap").getBMap();
                    let view = map.getViewport(eval(pointArr));
                    let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
                    let centerPoint = view.center;
                    map.centerAndZoom(centerPoint, mapZoom);
                }, 200);
            },
        }
    };
</script>

<style  rel="stylesheet/scss" lang="scss">
    .task-form {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }
    .filter-item-right {
        display: block;
        float: right;
        margin-bottom: 10px;
    }
</style>
